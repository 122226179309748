import { createTheme } from '@ltvco/refresh-lib/theme';

export const theme = createTheme({
  typography: {
    button: { textTransform: 'none', fontSize: '1rem', fontWeight: 'bold' },
    fontSize: 16,
    htmlFontSize: 16,
    fontFamily: "'Lato', sans-serif",
    h1: {
      fontSize: '1.875rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    caption: {
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.2,
    },
  },
  components: {
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: () => ({
          '&.unrecorded-item': {
            color: '#638A3A',
          },
          '&.hidden-section-icon': {
            color: '#4a3b8f',
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#FACEDA',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            padding: 12,
          },
          div: {
            height: 40,
            backgroundColor: '#fff',
          },
          '& .MuiFormLabel-root': {
            top: -8,
          },
          '& .Mui-focused': {
            top: 0,
          },
          '& .MuiFormLabel-filled': {
            top: 0,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: 'rgba(0, 0, 0, 0.87)',
      light: '#0000001A',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FCE7ED',
      dark: '#FCE7ED',
      light: '#FCE7ED',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      main: '#FACEDA',
      dark: '#FCE7ED',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      main: 'rgba(0, 0, 0, 0.87)',
      light: '#0000001A',
      dark: '#000',
      contrastText: '#fff',
    },
    error: {
      light: '#dc00151f',
      main: '#DC0015',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#F8F8F8',
      action: '#EEF0F3',
    },
    text: {
      primary: '#0A0B0D',
      secondary: '#5B616E',
      disabled: '#929AAB',
      contrastText: '#fff',
    },
    barChart: {
      main: 'rgba(0, 0, 0, 0.87)',
      light: '#FACEDA',
      dark: '#FCE7ED',
      contrastText: '#000',
    },
    lineChart: {
      main: 'rgba(0, 0, 0, 0.87)',
      light: '#757575',
      dark: '#DC0015',
      contrastText: '#000',
    },
    progress: {
      main: 'rgba(0, 0, 0, 0.87)',
      light: '#757575',
      dark: '#DC0015',
      contrastText: '#FACEDA',
    },
    premium: {
      main: '#FCC41C',
    },
    maps: {
      main: '#1F2E64',
      light: '#3388FF',
    },
    listingPins: {
      main: '#EC413D',
      light: '#4AA351',
      dark: '#4A3B8F',
      contrastText: '#81b44c',
    },
    listingPinsVisited: {
      main: '#f8a09e',
      light: '#9FCBA2',
      dark: '#9F97C1',
    },
    marketInsights: {
      main: '#000',
      contrastText: '#fff',
    },
    threatDetection: {
      main: '#DC0015',
      light: '#E1FAEA',
      dark: '#81B44C',
      contrastText: '#FFF1F1',
    },
    labelsAndLinks: {
      main: '#757575',
    },
  },
});
