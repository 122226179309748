import { CustomIcons } from '@ltvco/refresh-lib/theme';
import type { Owner } from '@ltvco/refresh-lib/v1';
import {
  Email,
  Person2,
  Phone,
  Radar,
  TaskAlt,
  Work,
} from '@mui/icons-material';

export const getEmailNavLinkData = (
  potentialOwnersLength: number,
  owner: Owner
) => {
  return [
    {
      href: '#email-overview',
      sectionId: '#email-overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
    },
    {
      href: '#email-fraud-check-section',
      sectionId: '#email-fraud-check-section"',
      text: 'FraudScan Summary',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'fraud_check',
    },
    {
      href: '#potential-owners-section',
      sectionId: '#potential-owners-section',
      text: 'Potential Owners',
      reportItemsCount: potentialOwnersLength,
      notificationSection: 'owners',
    },
    {
      href: '#possible-photos-section',
      sectionId: '#possible-photos-section',
      text: 'Possible Photos',
      reportItemsCount: owner.images?.length || null,
      notificationSection: 'photos',
    },
    {
      href: '#phone-numbers-section',
      sectionId: '#phone-numbers-section',
      text: 'Phone Numbers',
      reportItemsCount: owner.phones.length,
      notificationSection: 'phones',
    },
    {
      href: '#email-section',
      sectionId: '#email-section',
      text: 'Emails',
      reportItemsCount: owner.emails.length,
      notificationSection: 'emails',
    },
    {
      href: '#address-history-section',
      sectionId: '#address-history-section',
      text: 'Address History',
      reportItemsCount: owner.addresses.length,
      notificationSection: 'addresses',
    },
    {
      href: '#jobs-section',
      sectionId: '#jobs-section',
      text: 'Professional Background',
      reportItemsCount: owner.jobs.length,
      notificationSection: 'jobs',
    },
    {
      href: '#education-section',
      sectionId: '#education-section',
      text: 'Education',
      reportItemsCount: owner.educations.length,
      notificationSection: 'educations',
    },
    {
      href: '#social-media-section',
      sectionId: '#social-media-section',
      text: 'Social & Websites',
      reportItemsCount: owner.profiles.length + owner.usernames.length,
      notificationSection: 'socials',
    },
    {
      href: '#possible-owned-assets-section',
      sectionId: '#possible-owned-assets-section',
      text: 'Assets',
      reportItemsCount:
        (owner?.ownedAssets?.real_estate?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.watercraft?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.aircraft?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.automobiles?.length ?? 0),
      notificationSection: 'real_estate',
    },
  ];
};

export const getEmailNavLinkDataV2 = (
  potentialOwnersLength: number,
  owner: Owner
) => {
  return [
    {
      href: '#email-overview',
      labelText: 'Overview',
      icons: [<Email sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="large" />],
    },
    {
      href: '#email-fraud-check-section',
      labelText: 'FraudScan Summary',
      icons: [<Radar sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="small" />],
      hideReportItemsCount: true,
    },
    {
      labelText: 'Potential Owners',
      icons: [
        <Person2 sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="small" />,
      ],
      childItems: [
        {
          href: '#potential-owners-section',
          labelText: 'Potential Owners',
          reportItemsCount: potentialOwnersLength,
        },
        {
          href: '#possible-photos-section',
          labelText: 'Possible Photos',
          reportItemsCount: owner.images?.length || null,
        },
      ],
    },
    {
      labelText: 'Contact Information',
      icons: [<Phone sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="small" />],
      childItems: [
        {
          href: '#phone-numbers-section',
          labelText: 'Phone Numbers',
          reportItemsCount: Math.max(owner.phones.length - 1, 0),
        },
        {
          href: '#email-section',
          labelText: 'Email Addresses',
          reportItemsCount: owner.emails.length,
        },
        {
          href: '#address-history-section',
          labelText: 'Address History',
          reportItemsCount: owner.addresses.length,
        },
        {
          href: '#social-media-section',
          sectionId: '#social-media-section',
          labelText: 'Social Media',
          reportItemsCount: owner.profiles.length + owner.usernames.length,
        },
      ],
    },
    {
      labelText: 'Jobs & Educations',
      icons: [<Work sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="small" />],
      childItems: [
        {
          href: '#jobs-section',
          sectionId: '#jobs-section',
          labelText: 'Jobs',
          reportItemsCount: owner.jobs.length,
        },
        {
          href: '#education-section',
          sectionId: '#education-section',
          labelText: 'Education',
          reportItemsCount: owner.educations.length,
        },
      ],
    },
    {
      labelText: 'Assets',
      icons: [<CustomIcons.OwnedAssetsIcon color="primary" fontSize="small" />],
      href: '#possible-owned-assets-section',
      reportItemsCount:
        (owner?.ownedAssets?.real_estate?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.watercraft?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.aircraft?.length ?? 0) +
        (owner?.ownedAssets?.vehicles?.automobiles?.length ?? 0),
    },
    {
      labelText: 'Next Steps',
      icons: [
        <TaskAlt sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="small" />,
      ],
      childItems: [
        {
          href: '#Notes-section',
          labelText: 'Notes',
          hideReportItemsCount: true,
        },
        {
          href: '#related-reports-section',
          labelText: 'Related Reports',
          hideReportItemsCount: true,
        },
        {
          href: '#report-rating',
          labelText: 'Report Rating',
          hideReportItemsCount: true,
        },
      ],
    },
  ];
};
