import {
  useReport,
  ReportLoading,
  UsernameOverview,
  type ReportOptions,
  ReportFactory,
  ReportNullState,
  DebugMenu,
} from '@ltvco/refresh-lib/v1';
import { getSocialNavLinkData } from 'navLinkData/socialNavLinkData';
import { useState } from 'react';
import type { ReportRouteProps } from './types';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import {
  DateUtil,
  isZeroed,
  useScrollToSectionOnNavigate,
} from '@ltvco/refresh-lib/utils';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import {
  AddressHistorySection,
  EducationSection,
  EmailSection,
  JobsSection,
  NotesSection,
  PhoneSection,
  PotentialOwners,
  RelatedReportsSection,
  ReportActionsWithDateUpdated,
  ReportNavigationMenu,
  type ReportNavigationMenuProps,
  ReportNavigationMobileMenu,
  ReportRating,
  SocialSection,
} from '@ltvco/refresh-lib/v2';

interface SocialReportProps extends ReportRouteProps {}

const reportType = 'username';

export function SocialReportPage({ permalink }: SocialReportProps) {
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: true,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={9} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const report = ReportFactory.create(queryResult?.data, 'username');

  if (!report) return <ReportLoading menuItems={9} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const owner = report.getOwner(reportOptions);
  const {
    ownerName,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    usernames,
    addresses,
    relatives,
  } = owner;

  const socialNavLinkData = getSocialNavLinkData(
    owner,
    report?.data?.people?.length ?? 0
  );

  document.title = `${report.username} - NumbgerGuru`;
  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';
  return (
    <Box>
      <Stack direction="row">
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <ReportNavigationMenu
            reportType="Social Report"
            headerTitle={ownerName || report.username}
            menuItems={
              socialNavLinkData as ReportNavigationMenuProps['menuItems']
            }
          />
        </Box>
        <Box marginTop={0}>
          <Box
            sx={{
              height: 30,
              backgroundColor: '#f8f8f8',
              position: 'sticky',
              marginBottom: '-10px',
              marginX: -1,
              top: 52,
              zIndex: 10,
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'block',
              },
            }}
          />

          <ReportActionsWithDateUpdated
            reportType={reportType}
            reportTitle="Username"
            dateUpdated={reportUpdateDate}
            reportUpgraded={false}
          />

          <UsernameOverview
            reportType={reportType}
            username={report.username}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) =>
              handleSetReportOptions({ showHighConfidenceDataOnly: value })
            }
          />
          <PotentialOwners
            potentialOwners={report.data.people || []}
            potentialOwnerIndex={reportOptions.potentialOwnerIndex}
            setPotentialOwnerIndex={(index: number) => {
              handleSetReportOptions({ potentialOwnerIndex: index });
            }}
            personName={ownerName || report.username}
            permalink={permalink}
          />
          <PhoneSection
            personName={ownerName || report.username}
            phoneList={phones}
            permalink={permalink}
          />
          <EmailSection
            personName={ownerName || report.username}
            emailList={emails}
            permalink={permalink}
          />
          <AddressHistorySection
            addresses={addresses}
            personName={ownerName || report.username}
            permalink={permalink}
          />
          <SocialSection
            personName={ownerName || report.username}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />
          <JobsSection
            personName={ownerName || report.username}
            jobs={jobs}
            permalink={permalink}
          />

          <EducationSection
            personName={ownerName || report.username}
            educations={educations}
            permalink={permalink}
          />

          <NotesSection permalink={permalink} />
          <RelatedReportsSection
            reportType="username"
            personName={ownerName || report.username}
            potentialOwners={report.data.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
          />
          <ReportRating
            rating={report.data.meta?.rating || null}
            report_type={reportType}
          />
        </Box>
      </Stack>
      <ReportNavigationMobileMenu
        reportType="Social Report"
        headerTitle={ownerName || report.username}
        menuItems={socialNavLinkData as ReportNavigationMenuProps['menuItems']}
      />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </Box>
  );
}
