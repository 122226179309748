import { Navigate, useLocation } from 'react-router-dom';
import {
  useSession,
  isUserLoggedIn,
  invalidateSession,
} from '@ltvco/refresh-lib/v1';
import { useEffect } from 'react';
import { goToLogin } from 'utils/goToLogin';
import { useQueryClient } from '@ltvco/refresh-lib/vendors';
import { useReactivation } from '@ltvco/refresh-lib/payments';

interface AuthenticatedRouteProps {
  children: JSX.Element;
}

export function AuthenticatedRoute({ children }: AuthenticatedRouteProps) {
  const {
    session: { account },
  } = useSession(); // should be replaced by the user_info object
  const queryClient = useQueryClient();
  const isNonCompliant = account?.account.user_info.noncompliant;
  const location = useLocation();
  const complianceRoutes = [
    '/compliance-form',
    '/noncompliance',
    '/compliance-prohibited',
    '/compliance-permitted',
    '/compliance-recomply',
  ];

  useEffect(() => {
    handleFreshnessHubUsers();
  });

  function handleFreshnessHubUsers() {
    if (!account?.account || complianceRoutes.includes(location.pathname))
      return;
    if (account?.account?.staff_info?.staff) return;
    if (
      account?.account.user_info?.design_name === 'freshness' ||
      account?.account.user_info?.design_version === 'v2'
    ) {
      window.location.href = 'https://www.numberguru.com/app/';
    }
  }

  useReactivation({ currentPathName: location.pathname });

  if (!isUserLoggedIn()) {
    invalidateSession();
    const url = location.pathname + location.search + location.hash;
    queryClient.clear();

    setTimeout(() => {
      window.location.reload();
    }, 500);

    if (
      import.meta.env.VITE_TARGET_ENV &&
      !['production'].includes(import.meta.env.VITE_TARGET_ENV)
    ) {
      return <Navigate to="/login" state={{ next: url }} replace />;
    } else {
      goToLogin('https://www.numberguru.com');
      return null;
    }
  }
  if (isNonCompliant && !complianceRoutes.includes(location.pathname)) {
    return (
      <Navigate to={`/noncompliance`} state={{ from: location }} replace />
    );
  }

  return children;
}
