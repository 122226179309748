import { useQueryParams } from 'utils/useQueryParams';

import { styled, useTheme, Box } from '@ltvco/refresh-lib/theme';
import { SearchUnclaimedMoneyForm } from '@ltvco/refresh-lib/v1';

const StyledIFrame = styled('iframe')(({ theme }) => ({
  border: '0',
  width: '100%',
  minHeight: '7800px',

  [theme.breakpoints.up('sm')]: {
    minHeight: '3700px',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '2700px',
  },
}));

const PageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    paddingTop: '100px',
  },

  [theme.breakpoints.down(767)]: {
    paddingTop: '0px',
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '240px',
  backgroundColor: 'white',
  padding: '3rem 3rem 0',

  [theme.breakpoints.down('md')]: {
    height: '360px',
  },
}));

export function UnclaimedMoneySearch() {
  const { palette } = useTheme();
  const queryParams = useQueryParams();
  const fname = queryParams.get('fname') || '';
  const lname = queryParams.get('lname') || '';
  const state = queryParams.get('state') || '';

  const iframeColors = {
    cta: '000000',
    header: 'FCE7ED',
    sort: '000000',
    text: '030c0d',
    results: 'FCE7ED',
  };
  const baseURL = `https://www.numberguru.com/iframe/unclaimed/?CTA=${iframeColors.cta}&header=${iframeColors.header}&sort=${iframeColors.sort}&text=${iframeColors.text}&header_text=${iframeColors.text}&results_header=${iframeColors.results}&fname=${fname}&lname=${lname}&state=${state}`;
  const iframeURL = `${baseURL}&fn=${fname}&ln=${lname}&state=${state}`;

  return (
    <PageContainer>
      <FormContainer>
        <SearchUnclaimedMoneyForm
          fromIframePage
          fname={fname}
          lname={lname}
          state={state}
        />
      </FormContainer>
      <StyledIFrame title="unclaimed-money-frame" src={iframeURL} />
    </PageContainer>
  );
}
