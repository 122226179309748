/*
  Once we test and mature this code a little more and add the functionality to pull plans from the API,
  we can evaluate what of this code should be made shared in the refresh-lib
*/
import {
  costPerReport,
  savings,
  SubscriptionPlan,
} from '@ltvco/refresh-lib/v1';

export interface CurrentPlan {
  title: string;
  amount: number;
  /** Renewal period in months */
  renewalPeriod: number;
  /** Report limit */
  limit: number;
  costPerReport?: number;
}

export const planTitles: {
  [key: number]: { title: string; description: string };
} = {
  100: { title: 'Starter', description: 'Starting small and simple' },
  200: { title: 'Standard', description: 'Double your search capacity' },
  400: { title: 'Premium', description: 'Your premium reports package' },
  1000: {
    title: 'Professional',
    description: 'Take your business to the next level',
  },
};

const fallbackTitles: { [key: number]: { title: string } } = {
  100: { title: 'Lite User' },
  200: { title: 'Lite User' },
  400: { title: 'Plus User' },
  2000: { title: 'Elite User' },
};

const mostPopularPlanLimit = 200;

export function getPlanTitleAndDescription(planLimit: number) {
  return (
    planTitles[planLimit] ||
    fallbackTitles[planLimit] || { title: 'Power User' }
  );
}

/** Returns the cost per report of the plan if it's not unlimited */
export function planCostPerReport(plan: {
  amount: number;
  renewalPeriod: number;
  limit: number;
}) {
  if (plan.limit === -1) return;

  return costPerReport(plan.amount, plan.renewalPeriod, plan.limit);
}

/** Returns the savings of the plan compared to the current plan */
export function planSavings(plan: SubscriptionPlan, currentPlan: CurrentPlan) {
  if (currentPlan.limit === -1 || plan.monthly_report_limit === -1) return;

  return savings(
    currentPlan.amount,
    currentPlan.renewalPeriod,
    currentPlan.limit,
    plan.amount / 100,
    plan.renewal_period,
    plan.monthly_report_limit
  );
}

/** Returns the plans that are better than the current plan */
export function getPlans(
  brandPlans: SubscriptionPlan[],
  currentPlan: CurrentPlan
) {
  return brandPlans
    .filter((plan) => plan.monthly_report_limit > currentPlan.limit)
    .map((plan) => mapPlan(plan, currentPlan));
}

export function mapPlan(plan: SubscriptionPlan, currentPlan: CurrentPlan) {
  return {
    key: plan.unique_key,
    ...getPlanTitleAndDescription(plan.monthly_report_limit),
    amount: plan.amount / 100,
    limit: plan.monthly_report_limit,
    renewalPeriod: plan.renewal_period,
    renewalPeriodType: plan.renewal_period_type,
    costPerReport: planCostPerReport({
      amount: plan.amount / 100,
      renewalPeriod: plan.renewal_period,
      limit: plan.monthly_report_limit,
    }),
    savings: planSavings(plan, currentPlan),
    mostPopular: mostPopularPlanLimit === plan.monthly_report_limit,
  };
}

export function getBillingFrequency(renewalPeriod: number) {
  switch (renewalPeriod) {
    case 1:
      return 'monthly';
    case 3:
      return 'every 3 months';
    case 12:
      return 'annually';
    default:
      return 'N/A';
  }
}
