import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { goToLogin } from 'utils/goToLogin';
import { useSnackbar } from '@ltvco/refresh-lib/v1';
import type { ErrorRequestData, ResponseError } from '@ltvco/refresh-lib/utils';
import {
  QueryClient,
  QueryClientProvider,
  ReactQueryDevtools,
} from '@ltvco/refresh-lib/vendors';

interface QueryWrapperProps {
  children: JSX.Element;
  handleLogout: (ga4Ttracker?: () => void) => void;
  trackEventGA4: (eventToTrack: object, eventName?: string) => void;
  setTosState: ({
    modalOpen,
    requestData,
  }: {
    modalOpen: boolean;
    requestData: ErrorRequestData['body'];
  }) => void;
}

interface ResponseData {
  errors: string[];
  pp_version_bad?: string;
  tos_version_bad?: string;
}

export function QueryWrapper({
  children,
  handleLogout,
  trackEventGA4,
  setTosState,
}: QueryWrapperProps) {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          onError: (error) => {
            globalErrorHandler(error);
          },
          refetchOnWindowFocus: false,
        },
        mutations: {
          onError: (error) => {
            globalErrorHandler(error);
          },
        },
      },
    })
  );

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const ERROR_HANDLERS = {
    451: handleLegalRequiredError,
    426: handleUpgradeRequiredError,
    401: handleUnathorizedError,
    423: handleSessionBlockedError,
    429: handleRateLimitError,
    400: handleBadRequestError,
    404: handleNotFoundError,
  };

  function handleBadRequestError(responseData: ResponseData) {
    trackEventGA4({
      response: '400',
      message: 'BAD_REQUEST',
      event_name: 'whoops',
    });
    navigate('/error', { state: responseData });
    return;
  }

  function handleNotFoundError(responseData: ResponseData) {
    trackEventGA4({
      response: '404',
      message: 'PAGE_NOT_FOUND',
      event_name: 'whoops',
    });
    navigate('/error', { state: responseData });
    return;
  }
  function handleLegalRequiredError(
    responseData: ResponseData,
    requestData: ErrorRequestData
  ) {
    trackEventGA4({
      response: '451',
      message: 'LEGAL_REQUIRED',
      event_name: 'whoops',
    });
    setTosState({ modalOpen: true, requestData: requestData.body });
  }
  function handleUnathorizedError(responseData: ResponseData) {
    handleLogout(() =>
      trackEventGA4({
        response: '401',
        message: 'UNAUTHORIZED',
        event_name: 'whoops',
      })
    );
  }

  function handleUpgradeRequiredError(responseData: ResponseData) {
    trackEventGA4({
      response: '426',
      message: 'UPDATE_REQUIRED',
      event_name: 'whoops',
    });
    navigate('/upgrade/plan');
    return;
  }

  function handleSessionBlockedError(responseData: ResponseData) {
    trackEventGA4({
      response: '423',
      message: 'SESSION_BLOCKED',
      event_name: 'whoops',
    });
    handleUnathorizedError(responseData);
    enqueueSnackbar(
      `It looks like you've been logged out because your account is being used by another person or device`,
      {
        variant: 'error',
      }
    );
    goToLogin('https://www.numberguru.com');
  }

  function handleRateLimitError(
    responseData: ResponseData,
    requestData: ErrorRequestData
  ) {
    trackEventGA4({
      response: '429',
      message: 'RATE_LIMIT',
      event_name: 'whoops',
    });
    navigate('/captcha', {
      state: {
        requestData,
      },
    });
  }

  async function globalErrorHandler(error: ResponseError | any) {
    const status = error?.response?.status || null;

    if (!status) {
      return;
    }

    if ((status >= 200 && status < 300) || error.passthrough) {
      return;
    }

    const errorHandler = ERROR_HANDLERS[status as keyof typeof ERROR_HANDLERS];
    if (errorHandler) {
      // Some request may access the response body before the error handler and .json() will fail with
      // "Failed to execute 'json' on 'Response': body stream already read" - RS-20230606
      let data: ResponseData;
      try {
        data = await error.response?.json();
      } catch (_e) {
        data = {
          errors: ['Failed to parse response body global error handler fn'],
        };
      }
      const requestData = error.request;
      errorHandler(data, requestData);
    } else {
      navigate('/error');
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <ReactQueryDevtools />
        {children}
      </>
    </QueryClientProvider>
  );
}

export default QueryWrapper;
