import {
  UnclaimedMoneyHeader,
  SearchUnclaimedMoneyForm,
  HelpfulTips,
  Faq,
} from '@ltvco/refresh-lib/v1';
import {
  unclaimedMoneyFaq,
  unclaimedMoneyHelpfulTips,
} from './unclaimedMoneyConstants';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Divider, styled } from '@ltvco/refresh-lib/theme';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#F9F7FF',
  border: '0.5px solid',
  borderColor: theme.palette.success.main,
  padding: '15px',
  boxShadow: theme.palette.secondary.main,
}));

function useQueryParams(params: string) {
  return useMemo(() => new URLSearchParams(params), [params]);
}

export function UnclaimedMoney() {
  const { search } = useLocation();
  const queryParams = useQueryParams(search);
  const fname = queryParams.get('fname') || '';
  const lname = queryParams.get('lname') || '';
  const state = queryParams.get('state') || '';

  useEffect(() => {
    document.title = 'Unclaimed Money - NumberGuru';
  }, []);

  return (
    <>
      <UnclaimedMoneyHeader />
      <StyledCard>
        <SearchUnclaimedMoneyForm fname={fname} lname={lname} state={state} />
        <Divider sx={{ marginBottom: 3 }} />
        <HelpfulTips tips={unclaimedMoneyHelpfulTips} />
      </StyledCard>
      <Divider sx={{ marginBottom: 5, marginTop: 3 }} />
      <Faq faqItems={unclaimedMoneyFaq} />
    </>
  );
}
