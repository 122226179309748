import type { Owner } from '@ltvco/refresh-lib/v1';
import { Share, Person2, Phone, Work, TaskAlt } from '@mui/icons-material';

export const getSocialNavLinkData = (
  owner: Owner,
  potentialOwnersLength: number
) => {
  return [
    {
      href: '#username-overview',
      labelText: 'Overview',
      icons: [<Share sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="large" />],
    },
    {
      labelText: 'Potential Owners',
      icons: [
        <Person2 sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="small" />,
      ],
      childItems: [
        {
          href: '#potential-owners-section',
          labelText: 'Potential Owners',
          reportItemsCount: potentialOwnersLength,
        },
        {
          href: '#possible-photos-section',
          labelText: 'Possible Photos',
          reportItemsCount: owner.images?.length || null,
        },
      ],
    },
    {
      labelText: 'Contact Information',
      icons: [<Phone sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="small" />],
      childItems: [
        {
          href: '#phone-numbers-section',
          labelText: 'Phone Numbers',
          reportItemsCount: Math.max(owner.phones.length - 1, 0),
        },
        {
          href: '#email-section',
          labelText: 'Email Addresses',
          reportItemsCount: owner.emails.length,
        },
        {
          href: '#address-history-section',
          labelText: 'Address History',
          reportItemsCount: owner.addresses.length,
        },
        {
          href: '#social-media-section',
          sectionId: '#social-media-section',
          labelText: 'Social Media',
          reportItemsCount: owner.profiles.length + owner.usernames.length,
        },
      ],
    },
    {
      labelText: 'Jobs & Educations',
      icons: [<Work sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="small" />],
      childItems: [
        {
          href: '#jobs-section',
          sectionId: '#jobs-section',
          labelText: 'Jobs',
          reportItemsCount: owner.jobs.length,
        },
        {
          href: '#education-section',
          sectionId: '#education-section',
          labelText: 'Education',
          reportItemsCount: owner.educations.length,
        },
      ],
    },
    {
      labelText: 'Next Steps',
      icons: [
        <TaskAlt sx={{ color: 'rgba(0, 0, 0, 0.87)' }} fontSize="small" />,
      ],
      childItems: [
        {
          href: '#Notes-section',
          labelText: 'Notes',
          hideReportItemsCount: true,
        },
        {
          href: '#related-reports-section',
          labelText: 'Related Reports',
          hideReportItemsCount: true,
        },
        {
          href: '#report-rating',
          labelText: 'Report Rating',
          hideReportItemsCount: true,
        },
      ],
    },
  ];
};
