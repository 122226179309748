import { styled } from '@ltvco/refresh-lib/theme';
import { appRequests } from 'appRequests';
import { constants } from 'appConstants';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link,
} from 'react-router-dom';
import type { SideMenuItem } from '@ltvco/refresh-lib/v2';
import { AlertProvider } from '@ltvco/refresh-lib/v1';
import {
  createAppConfig,
  AppRequests,
  AppConfig,
  AppConstants,
} from '@ltvco/refresh-lib/ctx';
import type { ResponseError } from '@ltvco/refresh-lib/utils';
import { ExitToApp } from '@mui/icons-material';

const MenuItem = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.fontSize * 1.125,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  svg: {
    marginRight: theme.spacing(1),
  },
}));

export function LibContextWrapper({
  children,
  trackEventGA4,
  trackEvent,
  logError,
  handleLogoutClick,
}: {
  children: React.ReactNode | React.ReactNode[];
  trackEventGA4: (eventToTrack: object, eventName?: string) => void;
  trackEvent: (category: string, action: string, label?: string) => void;
  logError: (
    context: string,
    error: Error | ResponseError,
    url: string | undefined
  ) => void;
  handleLogoutClick: () => void;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const defaultConfig = createAppConfig();
  const { sideMenuItems } = constants.config;

  function flattenMenuItems(menuItems: SideMenuItem[]): SideMenuItem[] {
    return menuItems.flatMap(({ childItems, ...item }) => [
      item,
      ...(childItems?.length ? flattenMenuItems(childItems) : []),
    ]);
  }

  const flatMenu = flattenMenuItems(constants.config.sideMenuItems);
  const menuItems = flatMenu.map((item) => {
    return (
      <MenuItem to={item.route} data-cy={`menu_${item['data-cy']}`}>
        {item.icon} {item.text}
      </MenuItem>
    );
  });

  const appConfig = {
    ...defaultConfig,
    routingUtils: {
      ...defaultConfig.routingUtils,
      navigate: navigate,
      location: location,
      useSearchParams,
    },
    logError: logError,
    trackEvent: trackEvent,
    trackEventGA4: trackEventGA4,
  };

  const appConstants = {
    ...constants,
    config: {
      ...constants.config,
      menuItems: [
        ...menuItems,
        <MenuItem
          to="#"
          onClick={() => handleLogoutClick()}
          data-cy="menu_log_out_btn"
        >
          <ExitToApp sx={{ color: 'rgba(0, 0, 0, 0.87)' }} /> Log Out
        </MenuItem>,
      ],
      sideMenuItems: [
        ...sideMenuItems,
        {
          route: '',
          icon: '',
          text: 'Logout',
          name: 'logout',
          state: '',
          'data-cy': 'menu_log_out_btn',
          action: handleLogoutClick,
        },
      ],
    },
  };

  return (
    <AlertProvider maxSnack={3} preventDuplicate autoHideDuration={5000}>
      <AppRequests.Provider value={appRequests}>
        <AppConfig.Provider value={appConfig as any}>
          <AppConstants.Provider value={appConstants}>
            {children}
          </AppConstants.Provider>
        </AppConfig.Provider>
      </AppRequests.Provider>
    </AlertProvider>
  );
}
