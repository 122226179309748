import { constants } from 'appConstants';
const {
  links: { affiliateLink, aboutLink, doDontsLink, FaqLink, supportLink },
} = constants;

const unclaimedMoneyLink = '/rf/dashboard/unclaimed-money';
const careersLink = 'https://www.ltvco.com/careers/';
const upgradePlanLink =
  'https://docs.google.com/forms/d/e/1FAIpQLSdBQyTKG39eLvmc5t3wPR4XOQ80nH-xWelCbY8DLGUZ2f35Iw/viewform';

export const generateFooterLinks = ({
  contactUsHandler,
}: {
  contactUsHandler: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) => [
  {
    title: 'MORE SEARCHES',
    links: [{ text: 'Unclaimed Money', href: unclaimedMoneyLink }],
  },
  {
    title: 'RESOURCES',
    links: [
      { text: 'Affiliates', href: affiliateLink },
      {
        text: 'Need More Reports?',
        href: upgradePlanLink,
      },
    ],
  },
  {
    title: 'SUPPORT',
    links: [
      { text: 'Customer Care', href: supportLink },
      { text: 'Contact Us', href: '#', onClick: contactUsHandler },
      { text: "Do's & Dont's", href: doDontsLink },
      { text: "FAQ's", href: FaqLink },
    ],
  },
  {
    title: 'COMPANY',
    links: [
      { text: 'About NumberGuru', href: aboutLink },
      { text: 'Careers', href: careersLink },
    ],
  },
];
