import { BrandThemeProvider } from '@ltvco/refresh-lib/theme';
import { DateUtil, useScreenSize } from '@ltvco/refresh-lib/utils';
import { GrowthbookWrapper, useSession } from '@ltvco/refresh-lib/v1';
import { useEffect, useState } from 'react';
import { theme } from 'theme';

export function Growthbook({ children }: { children: JSX.Element }) {
  const isTestingEnv = import.meta.env.VITE_IS_TEST;
  const {
    session: { account },
  } = useSession();
  const { isMobile } = useScreenSize();
  const deviceType = isMobile ? 'web-mobile' : 'desktop';

  const [customAttributes, setCustomAttributes] = useState<
    Record<string, unknown>
  >({});
  const [isGbReady, setIsGbReady] = useState(isTestingEnv || false);

  useEffect(() => {
    if (!account) return;
    setCustomAttributes({
      'prop-2232-new': determineIfNewUser('2024-04-17T12:00:00.000-04:00'),
      'device-type': deviceType,
    });
  }, [account]);

  function determineIfNewUser(date: string) {
    const dateUtil = new DateUtil();
    const joinDate = account?.account?.user_info.join_date;

    if (joinDate) {
      return dateUtil.isAfter(joinDate, date);
    }
    return false;
  }

  return (
    <GrowthbookWrapper
      enableDevMode={true}
      apiHost={'https://gbbv.beenverified.com'}
      clientKey={'sdk-sH9Cly3zfuFM9mdc'}
      decryptionKey={'nZwGIW+xki4yOM6qBBx8Kg=='}
      subscribeToChanges={false}
      customAttributes={customAttributes}
      setIsGbReady={setIsGbReady}
      brandName={'numberguru'}
    >
      <BrandThemeProvider theme={theme}>{children}</BrandThemeProvider>
    </GrowthbookWrapper>
  );
}
