import { Navigate, Route, Routes } from 'react-router-dom';
import {
  type SearchData,
  NonCompliancePage,
  ComplianceFormPage,
  CompliancePermittedPage,
  ComplianceProhibitedPage,
  ComplianceRecomplyPage,
  ResetPasswordRequestForm,
  MagicLinkModal,
  ForgotLoginModal,
} from '@ltvco/refresh-lib/v1';
import {
  DashboardParent,
  Login,
  Report,
  AuthenticatedRoute,
  UnclaimedMoney,
  DarkWebScan,
  Billing,
  Lists,
  SearchPersonResultsContainer,
  ResetPassword,
  Reactivation,
  PublicRoute,
  NotFound,
  ErrorPage,
  UpgradePlanPage,
  ReportVelocityCaptcha,
  RecentReports,
  IdentityHub,
  Monitoring,
  UnclaimedMoneySearch,
} from 'routes';
import { useRef } from 'react';

type Props = {
  handleLogoutClick: () => void;
  handleSearchSuccess: (searchData: SearchData) => void;
  contactUsHandler: () => void;
  onLoginSuccess: () => void;
  openConfirmCancelModal: (downsellTest?: boolean) => void;
  onCancelConfirmCancelModal?: () => void;
};

export const AppRoutes = ({
  handleLogoutClick,
  handleSearchSuccess,
  contactUsHandler,
  onLoginSuccess,
  openConfirmCancelModal,
  onCancelConfirmCancelModal,
}: Props) => {
  const loginPageContainerRef = useRef(null);
  return (
    <Routes>
      <Route element={<NotFound />} path={'*'} />
      <Route element={<ErrorPage />} path={'/error'} />
      <Route index element={<Navigate to={`/dashboard`} />} />
      <Route
        path={`/login`}
        element={
          <PublicRoute>
            <Login
              onLoginSuccess={onLoginSuccess}
              ref={loginPageContainerRef}
            />
          </PublicRoute>
        }
      >
        <Route
          path="reset-password-request"
          element={
            <ResetPasswordRequestForm
              containerRef={loginPageContainerRef}
              open={false}
            />
          }
        />
        <Route
          path="magic-link"
          element={
            <MagicLinkModal containerRef={loginPageContainerRef} open={false} />
          }
        />
        <Route
          path="forgot-email"
          element={
            <ForgotLoginModal
              containerRef={loginPageContainerRef}
              open={false}
            />
          }
        />
      </Route>
      <Route
        path={`/reset-password`}
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path={`dashboard`}
        element={
          <AuthenticatedRoute>
            <DashboardParent
              contactUsHandler={contactUsHandler}
              handleLogout={handleLogoutClick}
              openConfirmCancelModal={openConfirmCancelModal}
            />
          </AuthenticatedRoute>
        }
      >
        <Route
          index
          element={
            <AuthenticatedRoute>
              <RecentReports />
            </AuthenticatedRoute>
          }
        />
        <Route
          path={`/dashboard/dark-web`}
          element={
            <AuthenticatedRoute>
              <DarkWebScan />
            </AuthenticatedRoute>
          }
        />
        <Route
          path={`/dashboard/lists`}
          element={
            <AuthenticatedRoute>
              <Lists />
            </AuthenticatedRoute>
          }
        />
        <Route
          path={`/dashboard/monitoring`}
          element={
            <AuthenticatedRoute>
              <Monitoring />
            </AuthenticatedRoute>
          }
        />
        <Route
          path={'/dashboard/identity-hub'}
          element={
            <AuthenticatedRoute>
              <IdentityHub />
            </AuthenticatedRoute>
          }
        />
        <Route
          path={'unclaimed-money'}
          element={
            <AuthenticatedRoute>
              <UnclaimedMoney />
            </AuthenticatedRoute>
          }
        />
        <Route
          path={`billing`}
          element={
            <AuthenticatedRoute>
              <Billing
                onCancelConfirmCancelModal={onCancelConfirmCancelModal}
              />
            </AuthenticatedRoute>
          }
        />
      </Route>
      <Route
        path={`/search/unclaimed`}
        element={
          <AuthenticatedRoute>
            <UnclaimedMoneySearch />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/report/:reportType`}
        element={
          <AuthenticatedRoute>
            <Report onSearchSuccess={handleSearchSuccess} />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/search/person`}
        element={<SearchPersonResultsContainer />}
      />
      <Route
        path={`/noncompliance`}
        element={
          <AuthenticatedRoute>
            <NonCompliancePage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/compliance-form`}
        element={
          <AuthenticatedRoute>
            <ComplianceFormPage link="/noncompliance" />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/compliance-permitted`}
        element={
          <AuthenticatedRoute>
            <CompliancePermittedPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/compliance-prohibited`}
        element={
          <AuthenticatedRoute>
            <ComplianceProhibitedPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/compliance-recomply`}
        element={
          <AuthenticatedRoute>
            <ComplianceRecomplyPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={'/reactivation'}
        element={
          <AuthenticatedRoute>
            <Reactivation />
          </AuthenticatedRoute>
        }
      />
      <Route
        index={true}
        path={'/upgrade/plan'}
        element={
          <AuthenticatedRoute>
            <UpgradePlanPage contactUsHandler={contactUsHandler} />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={'/captcha'}
        element={
          <AuthenticatedRoute>
            <ReportVelocityCaptcha />
          </AuthenticatedRoute>
        }
      />
    </Routes>
  );
};
