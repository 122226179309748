import { useEffect, useState } from 'react';
import {
  useSession,
  ReportLoading,
  type ReportOptions,
  ReportFactory,
  ReportNullState,
  type ReportMonitor,
  useReportMonitors,
  flattenBonusData,
  ReportChangesOverview,
  useReport,
  useRemouladeReportSnapshot,
  PdfSection,
  DebugMenu,
  type Owner,
} from '@ltvco/refresh-lib/v1';
import type { ReportRouteProps } from './types';
import {
  getPersonNavLinkData,
  getPersonNavLinkDataV2,
} from 'navLinkData/personNavLinkData';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import imgObituarySearch from '../../images/img_obituary-search.svg';
import {
  DateUtil,
  isZeroed,
  nameize,
  useScrollToSectionOnNavigate,
} from '@ltvco/refresh-lib/utils';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import {
  AddressHistorySection,
  AssetsSection,
  AssociatesSection,
  BankruptcySection,
  CourtRecords,
  CourtRecordsLoadingScreen,
  CriminalOrTrafficSection,
  EducationSection,
  EmailSection,
  JobsSection,
  LicensesAndPermitsSection,
  MaritalRecordsSection,
  MonitoringBannerSection,
  NameAncestrySection,
  NeighborsSection,
  NotesSection,
  PersonReportOverview,
  PhoneSection,
  RelatedReportsSection,
  RelativesSection,
  ReportActionsWithDateUpdated,
  ReportClaimingSection,
  ReportNavigationMenu,
  type ReportNavigationMenuProps,
  ReportNavigationMobileMenu,
  ReportRating,
  SocialSection,
} from '@ltvco/refresh-lib/v2';

interface PersonReportProps extends ReportRouteProps {}

const reportType = 'person';

export function PersonReportPage({
  permalink,
  isMonitored = false,
}: PersonReportProps) {
  const {
    session: { account },
  } = useSession();

  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  // I want to move all this logic into a hook that handles the report fetching - Corey Gibson - 2024-06-20
  const { currentMonitors, reportIsMonitored } = useReportMonitors();
  const [initialIsMonitored, setInitialIsMonitored] = useState(isMonitored);
  const coreResult = useReport(permalink, initialIsMonitored);
  const remouladeResult = useRemouladeReportSnapshot(
    permalink,
    initialIsMonitored
  );
  const currentUserInfo = account?.account?.user_info;
  const queryResult = (
    initialIsMonitored ? remouladeResult : coreResult
  ) as typeof coreResult;

  // If remoulade fails, we fall back to core. Most likely cause is race condition between creating monitor
  // and remoulade fetching data from core.
  useEffect(() => {
    if (remouladeResult.isError) {
      setInitialIsMonitored(false);
    }
  }, [remouladeResult.isError]);

  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [showBankruptcyData, setShowBankruptcyData] = useState<boolean>(false);
  const [showCriminalData, setShowCriminalData] = useState(false);
  const [isCourtRecordsLoading, setIsCourtRecordsLoading] = useState(false);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: true,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };

  const [activeClaiming, setActiveClaiming] = useState(false);
  const [disableClaiming, setDisableClaiming] = useState(false);

  useEffect(() => {
    setDisableClaiming(
      currentMonitors?.some((monitor: ReportMonitor) => {
        return (
          monitor.report_type === 'detailed_person_report' &&
          monitor.is_claimed &&
          permalink !== monitor.permalink
        );
      })
    );
    setActiveClaiming(
      currentMonitors?.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  const hideCriminalBJLData = account?.account.user_settings
    .hide_criminal_records as boolean;

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={14} />;
  }

  if (zeroed) {
    return <ReportNullState />;
  }

  const report = ReportFactory.create(queryResult?.data, 'person');
  const reportUpgraded = queryResult.data.meta.report_upgraded;
  const revealFreeBonusData = showCriminalData && !isCourtRecordsLoading;

  let showReportClaiming = false;
  if (
    currentUserInfo?.first_name &&
    currentUserInfo.last_name &&
    report.personName.includes(currentUserInfo.first_name) &&
    report.personName.includes(currentUserInfo.last_name)
  ) {
    showReportClaiming = true;
  }

  const MonitoringComponent =
    showReportClaiming && !disableClaiming ? (
      <ReportClaimingSection
        reportPermaLink={permalink}
        reportType={reportType}
        reportIsMonitoredAndClaimed={activeClaiming}
      />
    ) : reportIsMonitored ? null : (
      <MonitoringBannerSection
        reportPermaLink={permalink}
        reportType={reportType}
      />
    );

  if (!report) return <ReportLoading menuItems={14} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const owner = report.getOwner(reportOptions);
  const {
    ownerName,
    nameInfo,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    usernames,
    addresses,
    ownedAssets,
    marital,
    relatives,
    associates,
    neighbors,
    courts,
    bonusData,
  } = owner;
  const flattenedBonusData = flattenBonusData(bonusData);

  const personNavLinkData = getPersonNavLinkData(
    owner as Owner,
    showCriminalData,
    showBankruptcyData,
    hideCriminalBJLData,
    reportUpgraded,
    flattenedBonusData.length,
    relatives || []
  );

  const personNavLinkDataV2 = getPersonNavLinkDataV2(
    owner as Owner,
    hideCriminalBJLData,
    revealFreeBonusData,
    relatives || []
  );

  document.title = `${report.personName} - NumberGuru`;
  const dob = owner.identity?.dobs?.[0]?.date?.parsed?.year?.toString() ?? '';

  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';

  const delayForCourtRecords = 1000;

  function handleCourtRecordsLoadingScreen() {
    setIsCourtRecordsLoading(true);
    setTimeout(() => {
      setIsCourtRecordsLoading(false);
      setShowCriminalData(true);
    }, delayForCourtRecords * 7);
  }

  return (
    <Box>
      <Stack direction={'row'}>
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <ReportNavigationMenu
            reportType="Person Report"
            headerTitle={ownerName}
            menuItems={
              personNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
            }
          />
        </Box>
        <Box marginTop={0}>
          {reportIsMonitored && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={personNavLinkData}
            />
          )}
          <Box
            sx={{
              height: 30,
              backgroundColor: '#f8f8f8',
              position: 'sticky',
              marginBottom: '-10px',
              top: 52,
              zIndex: 10,
              display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
            }}
          />

          <ReportActionsWithDateUpdated
            reportType={reportType}
            reportTitle="Person"
            dateUpdated={reportUpdateDate}
            reportUpgraded={reportUpgraded}
          />
          <PersonReportOverview
            reportType={reportType}
            owner={owner as Owner}
            personName={ownerName}
            personOverviewItemsData={{
              hideCriminalBJLData,
              phones,
              emails,
              addresses,
              relatives,
              images: owner!.images,
              profiles,
              usernames,
              jobs,
              educations,
            }}
            dateUpdated={reportUpdateDate}
            reportUpgraded={revealFreeBonusData}
          />

          {MonitoringComponent}

          <PdfSection permalink={permalink} />

          <PhoneSection
            personName={ownerName}
            phoneList={phones}
            permalink={permalink}
          />

          <EmailSection
            personName={ownerName}
            emailList={emails}
            permalink={permalink}
          />

          <AddressHistorySection
            addresses={addresses}
            personName={ownerName}
            permalink={permalink}
          />

          <SocialSection
            personName={ownerName}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />

          <NameAncestrySection
            firstName={nameize(nameInfo?.parsed?.first ?? null)}
            surName={nameize(nameInfo?.parsed?.last ?? null)}
            yearOfBirth={dob}
          />

          <RelativesSection
            personName={ownerName}
            relatives={relatives || []}
            permalink={permalink}
          />

          <MaritalRecordsSection
            personName={ownerName}
            marital={marital}
            permalink={permalink}
          />

          <AssociatesSection
            personName={ownerName}
            associates={associates || []}
            permalink={permalink}
          />

          <NeighborsSection
            personName={ownerName}
            neighbors={neighbors || []}
            permalink={permalink}
          />

          {!hideCriminalBJLData && (
            <>
              {revealFreeBonusData ? (
                <>
                  <CriminalOrTrafficSection
                    criminalOrTrafficData={courts.criminal || []}
                    personName={ownerName}
                  />
                  <BankruptcySection
                    personName={ownerName}
                    bankruptcyData={courts.bankruptcy || []}
                  />
                  <LicensesAndPermitsSection
                    personName={ownerName}
                    bonusData={bonusData}
                  />
                </>
              ) : (
                <>
                  {isCourtRecordsLoading ? (
                    <CourtRecordsLoadingScreen
                      personName={ownerName}
                      delayDuration={delayForCourtRecords}
                    />
                  ) : (
                    <CourtRecords
                      handleCourtRecordsLoadingScreen={
                        handleCourtRecordsLoadingScreen
                      }
                    />
                  )}
                </>
              )}
            </>
          )}

          <JobsSection
            personName={ownerName}
            jobs={jobs}
            permalink={permalink}
          />

          <EducationSection
            personName={ownerName}
            educations={educations}
            permalink={permalink}
          />

          <AssetsSection
            personName={ownerName}
            ownedAssets={ownedAssets}
            permalink={permalink}
          />

          <NotesSection permalink={permalink} />

          <RelatedReportsSection
            reportType="person"
            personName={ownerName}
            potentialOwners={report.data.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
          />

          <ReportRating
            rating={report.data.meta?.rating || null}
            report_type={reportType}
          />
        </Box>
      </Stack>

      <ReportNavigationMobileMenu
        reportType="Person Report"
        headerTitle={ownerName}
        menuItems={
          personNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
        }
      />

      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </Box>
  );
}
