export const getPropertyNavLinkData = (
  potentialOwnersLength: number,
  possibleResidentsLength: number,
  buildingPermitsLength: number,
  deedsLength: number,
  liensLength: number,
  propertyDetailsLength: number,
  propertyValuesLength: number,
  equitiesCount: number,
  schoolsCount: number
) => {
  return [
    {
      href: '#overview',
      sectionId: '#overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
    },
    {
      href: '#possible-owners-section',
      sectionId: '#possible-owners-section',
      text: 'Possible Owners',
      reportItemsCount: potentialOwnersLength,
      notificationSection: 'owners',
    },
    {
      href: '#possible-residents-section',
      sectionId: '#possible-residents-section',
      text: 'Possible Residents',
      reportItemsCount: possibleResidentsLength,
      notificationSection: 'residents',
    },
    {
      href: '#lot-and-building-details-section',
      sectionId: '#lot-and-building-details-section',
      text: 'Property Details',
      reportItemsCount: propertyDetailsLength,
      notificationSection: 'lot_details',
    },
    {
      href: '#building-permits-section',
      sectionId: '#building-permits-section',
      text: 'Building Permits',
      reportItemsCount: buildingPermitsLength,
      notificationSection: 'permits',
    },
    {
      href: '#deeds-section',
      sectionId: '#deeds-section',
      text: 'Deeds',
      reportItemsCount: deedsLength,
      notificationSection: 'deeds',
    },
    {
      href: '#liens-section',
      sectionId: 'liens-section',
      text: 'Liens',
      reportItemsCount: liensLength,
      notificationSection: 'liens',
    },
    {
      href: '#estimated-value-section',
      sectionId: '#estimated-value-section',
      text: 'Property Valuation',
      reportItemsCount: propertyValuesLength,
      notificationSection: 'valuation',
    },
    {
      href: '#equity-and-loans-section',
      sectionId: '#equity-and-loans-section',
      text: 'Equity & Loans',
      reportItemsCount: equitiesCount,
      notificationSection: 'equity_loans',
    },
    {
      href: '#schools-nearby-section',
      sectionId: '#schools-nearby-section',
      text: 'Schools & Area',
      reportItemsCount: schoolsCount,
      notificationSection: 'schools',
    },
  ];
};
