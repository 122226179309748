import { useEffect } from 'react';
import {
  useReport,
  EmailOverview,
  ReportOptions,
  ReportLoading,
  ReportFactory,
  ReportNullState,
  Monitoring,
  ReportMonitor,
  useReportMonitors,
  useRemouladeReportSnapshot,
  ReportChangesOverview,
  ReportClaiming,
  useSession,
  DebugMenu,
  EmailFraudCheckSection,
} from '@ltvco/refresh-lib/v1';
import type { ReportRouteProps } from './types';
import {
  getEmailNavLinkData,
  getEmailNavLinkDataV2,
} from 'navLinkData/emailNavLinkData';
import { useState } from 'react';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import {
  DateUtil,
  isZeroed,
  useScrollToSectionOnNavigate,
} from '@ltvco/refresh-lib/utils';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import {
  AddressHistorySection,
  AssetsSection,
  EducationSection,
  EmailSection,
  JobsSection,
  NotesSection,
  PhoneSection,
  PotentialOwners,
  RelatedReportsSection,
  ReportActionsWithDateUpdated,
  ReportNavigationMenu,
  ReportNavigationMenuProps,
  ReportNavigationMobileMenu,
  ReportRating,
  SocialSection,
} from '@ltvco/refresh-lib/v2';

interface EmailReportProps extends ReportRouteProps {}

const reportType = 'email';

export function EmailReportPage({
  permalink,
  isMonitored = false,
}: EmailReportProps) {
  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const [initialIsMonitored, setInitialIsMonitored] = useState(isMonitored);
  const coreResult = useReport(permalink, initialIsMonitored);
  const remouladeResult = useRemouladeReportSnapshot(
    permalink,
    initialIsMonitored
  );
  const {
    session: { account },
  } = useSession();

  const currentUserInfo = account?.account?.user_info;
  const queryResult = (
    initialIsMonitored ? remouladeResult : coreResult
  ) as typeof coreResult;

  // If remoulade fails, we fall back to core. Most likely cause is race condition between creating monitor
  // and remoulade fetching data from core.
  useEffect(() => {
    if (remouladeResult.isError) {
      setInitialIsMonitored(false);
    }
  }, [remouladeResult.isError]);

  const zeroed = isZeroed(queryResult);
  const { hash, search } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: true,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };

  const { currentMonitors, reportIsMonitored } = useReportMonitors();
  const [activeMonitor, setActiveMonitor] = useState(false);
  const [activeClaiming, setActiveClaiming] = useState(false);

  useEffect(() => {
    setActiveClaiming(
      currentMonitors.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={10} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const report = ReportFactory.create(queryResult?.data, 'email');

  if (!report) return <ReportLoading menuItems={10} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const showReportClaiming =
    currentUserInfo?.email?.toLowerCase() ===
    report.emailAddress?.toLowerCase();

  const MonitoringComponent = showReportClaiming ? (
    <ReportClaiming
      permalink={permalink}
      reportType={reportType}
      reportIsMonitoredAndClaimed={activeClaiming}
    />
  ) : (
    <Monitoring permalink={permalink} reportType={reportType} />
  );

  const owner = report.getOwner(reportOptions);
  const {
    ownerName,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    usernames,
    addresses,
    ownedAssets,
    images,
    userIds,
    relatives,
  } = owner;

  const { supportingEmail } = report;

  const { fraud_score, deliverability, recent_abuse, is_leaked } =
    supportingEmail || {};

  const emailNavLinkDataV2 = getEmailNavLinkDataV2(
    report?.data.people?.length || 0,
    owner
  );

  const emailNavLinkData = getEmailNavLinkData(
    report?.data.people?.length || 0,
    owner
  );

  const queryParams = new URLSearchParams(search);
  const fallbackEmail = queryParams.get('email') || '';
  const lowerCaseEmailAddress =
    report?.emailAddress?.toLowerCase() ?? fallbackEmail;
  document.title = `${lowerCaseEmailAddress} - NumberGuru`;

  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';

  return (
    <Box>
      <Stack direction="row">
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <ReportNavigationMenu
            reportType="Email Report"
            headerTitle={lowerCaseEmailAddress}
            menuItems={
              emailNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
            }
          />
        </Box>

        <Box marginTop={0}>
          {activeMonitor && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={emailNavLinkData}
            />
          )}

          <>
            <Box
              sx={{
                height: 30,
                backgroundColor: '#f8f8f8',
                position: 'sticky',
                marginBottom: '-10px',
                marginX: -1,
                top: 52,
                zIndex: 10,
                display: {
                  xs: 'none',
                  sm: 'block',
                  md: 'block',
                  lg: 'block',
                },
              }}
            />

            <ReportActionsWithDateUpdated
              reportType={reportType}
              reportTitle="Email"
              dateUpdated={reportUpdateDate}
              reportUpgraded={false}
            />
          </>

          <EmailOverview
            reportType={reportType}
            email={lowerCaseEmailAddress}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) => {
              handleSetReportOptions({
                showHighConfidenceDataOnly: value,
              });
            }}
            personName={ownerName || lowerCaseEmailAddress}
            emailOverviewItemsData={{
              ownerName,
              emails,
              jobs,
              educations,
              phones,
              profiles,
              usernames,
              userIds,
              peopleCount: report.data.people?.length,
            }}
            Monitoring={MonitoringComponent}
          />
          <EmailFraudCheckSection
            fraudScore={fraud_score}
            deliverability={deliverability}
            abusiveBehavior={recent_abuse}
            leaked={is_leaked}
            showEmailFraudScan={supportingEmail != undefined}
            emailAddress={lowerCaseEmailAddress}
            showReportCTA
          />
          <PotentialOwners
            potentialOwners={report.data.people || []}
            potentialOwnerIndex={reportOptions.potentialOwnerIndex}
            setPotentialOwnerIndex={(index: number) => {
              handleSetReportOptions({ potentialOwnerIndex: index });
            }}
            personName={ownerName || lowerCaseEmailAddress}
            permalink={permalink}
          />

          <PhoneSection
            personName={ownerName || lowerCaseEmailAddress}
            phoneList={phones}
            permalink={permalink}
          />
          <EmailSection
            personName={ownerName || lowerCaseEmailAddress}
            emailList={emails}
            permalink={permalink}
          />
          <AddressHistorySection
            addresses={addresses}
            personName={ownerName || lowerCaseEmailAddress}
            permalink={permalink}
          />
          <SocialSection
            personName={ownerName || lowerCaseEmailAddress}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />
          <JobsSection
            personName={ownerName || lowerCaseEmailAddress}
            jobs={jobs}
            permalink={permalink}
          />
          <EducationSection
            personName={ownerName || lowerCaseEmailAddress}
            educations={educations}
            permalink={permalink}
          />
          <AssetsSection
            personName={ownerName || lowerCaseEmailAddress}
            ownedAssets={ownedAssets}
            permalink={permalink}
          />
          <NotesSection permalink={permalink} />
          <RelatedReportsSection
            reportType="email"
            personName={ownerName || lowerCaseEmailAddress}
            potentialOwners={report?.data?.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
          />
          <ReportRating
            report_type={reportType}
            rating={report.data.meta?.rating || null}
          />
        </Box>
      </Stack>
      <ReportNavigationMobileMenu
        reportType="Email Report"
        headerTitle={lowerCaseEmailAddress}
        menuItems={emailNavLinkDataV2 as ReportNavigationMenuProps['menuItems']}
      />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </Box>
  );
}
