import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';
import imgDarkWebEmail from './images/img-dark-web-email.svg';
import paymentRequired from './images/img_payment_required.svg';
import googlePay from './images/img_google_pay.svg';
import applePay from './images/img_apple_pay.svg';
import venmo from './images/img_venmo.svg';
import venmoButton from './images/img_venmo_button.svg';
import visa from './images/img_visa.svg';
import american_express from './images/img_amex.svg';
import discover from './images/img_discover.svg';
import genericCreditCard from './images/img_generic_credit_card.svg';
import paypal from './images/img_paypal.svg';
import masterCard from './images/img_mastercard.svg';
import imgGoogleStore from './images/img-google-play.svg';
import imgAppleStore from './images/img-appstore.svg';
import facebookLogo from './images/img_social_facebook.svg';
import facebookTwitter from './images/img_social_twitter.svg';
import imgExampleAircraft from './images/img_example_aircraft.svg';
import imgExampleAutomobile from './images/img_example_automobile.svg';
import imgExampleWatercraft from './images/img_example_watercraft.svg';
import imgMonitoringBell from './images/img_bell_monitoring.svg';
import MonitoringBell from './images/imgMonitoringBell.svg';
import imgIdHub from './images/id-hub.svg';
import imgAddressGeneric from './images/img_address_generic_no_bg.svg';
import imgBell from './images/img_bell.svg';
import imgDarkWebScan from './images/img_dark_web_scan.svg';
import imgSpecializedBackgroundCheck from './images/specialized-background-check.svg';
import imgSpecializedUnclaimedMoney from './images/specialized-unclaimed-money.svg';
import imgReportRating from './images/img_report_rating.svg';
import imgSuccessfulUpgrade from './images/img_successful_upgrade.svg';
import imgComputerLimitReached from './images/img_computer_limit_reached.svg';
import img24hr from './images/img_24hr.svg';
import img_pdf_glow from './images/img_pdf_glow.svg';
import neighborSpoofing from './images/neighbor-spoofing.svg';
import imgNoProperty from './images/no-property-img-fallback.svg';
import imgMonitorBanner from './images/img_neighborhood_safety.svg';
import imgAnimatedPersonMagnifying from './images/ani_person_magnifying.gif';
import imgCheckList from './images/icn_img_checklist.svg';
import imgAppStoreBadge from './images/img_apple_app_store_badge.svg';
import imgGooglePlayBadge from './images/img_badge_google_play.svg';
import imgMobileFreemium from './images/img_mobile_freemium.svg';
import imgExpiredEmailLink from './images/img_expired_email_link.svg';
import imgFallbackProperty from './images/img_fallback_property.svg';
import imgMapDefaultPin from './images/img_map_default_pin.png';
import imgMapMainHomePin from './images/img_map_main_home_pin.svg';
import imgMapSecondaryPin from './images/img_map_secondary_pin.svg';
import imgMapBoxPin from './images/img_pin.svg';
import { CustomIcons } from '@ltvco/refresh-lib/theme';
import ComplianceLogo from './images/img-alert.svg';
import SearchLogo from './images/search-logo.svg';
import imgCustomerService from './images/img_customer_service.svg';
import imgFraudCheck from './images/img_fraud_check.svg';
import imgNoticeLeave from './images/img_notice_leave.svg';
import imgMigrationCta from 'images/img_migration_cta.svg';
import type { ReportTypeOption } from '@ltvco/refresh-lib/v2';
import imgSingleSignOnCta from './images/img_sso_cta.svg';
import CriminalorTraffic from './images/imgCriminalTraffic.svg';
import bankrupticies from './images/imgBankruptcies.svg';
import Licenses from './images/imgLicenses.svg';
import {
  AccountCircle,
  AlternateEmail,
  AttachMoney,
  Email,
  Facebook,
  Home,
  List,
  NotificationsActive,
  NotificationsNone,
  Person,
  Phone,
  Restore,
  Search,
  Summarize,
} from '@mui/icons-material';

const baseUrl = `${window.location.protocol}//${window.location.host}`;
const reportTypeFilterOptions: { [key: string]: ReportTypeOption } = {
  detailed_person_report: {
    id: 'detailed_person_report',
    display: 'Person',
    eventLabel: 'Person',
  },
  reverse_phone_report: {
    id: 'reverse_phone_report',
    display: 'Phone',
    eventLabel: 'Phone',
  },
  property_report: {
    id: 'property_report',
    display: 'Property',
    eventLabel: 'Property',
  },
  social_network_report: {
    id: 'social_network_report',
    display: 'Email',
    eventLabel: 'Email',
  },
  username_report: {
    id: 'username_report',
    display: 'Username',
    eventLabel: 'Username',
  },
  sex_offender_report: {
    id: 'sex_offender_report',
    display: 'Neighborhood',
    eventLabel: 'Neighborhood',
  },
};

// TODO: should we extend the default config the same way we're doing with the theme? might be good for some props, other props maybe should be required
export const constants = {
  images: {
    brandLogo: logo,
    brandLogoMobile: logoSmall,
    imgAppleStore,
    imgGoogleStore,
    master: masterCard,
    visa: visa,
    american_express: american_express,
    discover: discover,
    genericCreditCard: genericCreditCard,
    paypal: paypal,
    googlePay: googlePay,
    paymentRequired: paymentRequired,
    applePay: applePay,
    venmo: venmo,
    venmoButton: venmoButton,
    searchLogo: SearchLogo,
    complianceLogo: ComplianceLogo,
    imgDarkWebEmail: imgDarkWebEmail,
    imgNoProperty,
    imgExampleAircraft: imgExampleAircraft,
    imgExampleWatercraft: imgExampleWatercraft,
    imgExampleAutomobile: imgExampleAutomobile,
    monitoringBell: imgMonitoringBell,
    imgMonitoring: MonitoringBell,
    imgIdHub,
    imgAddressGeneric,
    imgBell,
    imgDarkWebScan,
    imgSpecializedBackgroundCheck,
    imgSpecializedUnclaimedMoney,
    imgReportRating: imgReportRating,
    imgSuccessfulUpgrade: imgSuccessfulUpgrade,
    imgComputerLimitReached: imgComputerLimitReached,
    pdfLogo: img_pdf_glow,
    img24hr,
    neighborSpoofing,
    imgAnimatedPersonMagnifying,
    imgMagnifyingGlassNoResultsPage: '',
    imgMapDefaultPin: imgMapDefaultPin,
    imgMapMainHomePin: imgMapMainHomePin,
    imgMapSecondaryPin: imgMapSecondaryPin,
    imgMonitorBanner: imgMonitorBanner,
    imgMobileExpIcon: '',
    imgCopyIcon: '',
    imgContactlessIcon: '',
    imgUpdatesIcon: '',
    imgEtagsLogo: '',
    imgAddVehicle: '',
    imgNHFacts: '',
    imgMarketTrends: '',
    imgAmenities: '',
    imgWhatIsAVin: '',
    imgWhereIsVin: '',
    imgCheckList,
    imgAppStoreBadge,
    imgGooglePlayBadge,
    imgMobileFreemium,
    imgCustomerService,
    imgExpiredEmailLink,
    imgFallbackProperty,
    imgNoVehicleImageProvided: '',
    imgMapBoxPin,
    imgFlowerObituary: '',
    imgFraudCheck,
    imgNoticeLeave,
    imgMigrationCta,
    imgSingleSignOnCta,
    astrology: {
      imgAstrologyNumerology: '',
      imgAstrologyNumerologyV2: '',
      imgAstrologyNumerologyLoading: '',
      compatibilityImg: '',
      aries: '',
      taurus: '',
      gemini: '',
      cancer: '',
      leo: '',
      virgo: '',
      libra: '',
      scorpio: '',
      sagittarius: '',
      capricorn: '',
      aquarius: '',
      pisces: '',
      empty: '',
      emptyBackground: '',
      smallAries: '',
      smallTaurus: '',
      smallGemini: '',
      smallCancer: '',
      smallLeo: '',
      smallVirgo: '',
      smallLibra: '',
      smallScorpio: '',
      smallSagittarius: '',
      smallCapricorn: '',
      smallAquarius: '',
      smallPisces: '',
      smallAscendant: '',
    },
    CourtRecordsImages: {
      imgCriminalTraffic: CriminalorTraffic,
      imgBankruptcies: bankrupticies,
      imgLicenses: Licenses,
    },
  },
  links: {
    baseUrl: `${baseUrl}${
      import.meta.env.VITE_PATH_PREFIX?.includes('/')
        ? import.meta.env.VITE_PATH_PREFIX
        : `/${import.meta.env.VITE_PATH_PREFIX}`
    }`,
    joinLink: 'https://www.numberguru.com/lp/d8937f/4/select-plan',
    brandLink: import.meta.env.VITE_DEV_PROXY_SEARCH_PERSON_TEASER
      ? ''
      : `${baseUrl}`,
    aboutLink: 'https://numberguru.com/about',
    fcraLink: 'https://www.numberguru.com/fcra/',
    doDontsLink: 'https://www.numberguru.com/fcra/',
    tosLink: 'https://www.numberguru.com/terms-of-service/',
    privacyLink: 'https://www.numberguru.com/privacy-policy/',
    contactLink: 'https://www.numberguru.com/contact/',
    FaqLink: 'https://www.numberguru.com/faq/',
    dontSellInfoLink: 'https://www.numberguru.com/app/optout/search',
    affiliateLink: 'https://www.numberguru.com/affiliates',
    peopleSearchLink: '',
    reversePhoneLink: 'https://www.numberguru.com/phone',
    emailLookupLink: '',
    reverseAddressLink: '',
    contactForm: 'https://www.numberguru.com/contact/',
    supportLink: 'http://support.numberguru.com',
    twitterLink: '',
    facebookLink: '',
    blogLink: '',
    brandIosUrl: 'https://itunes.apple.com/us/app/number-guru/id431750800',
    brandAndroidUrl:
      'https://play.google.com/store/apps/details?id=com.numberguru&hl=en_US&gl=US&pli=1',
    rapidsLookupLink: `${baseUrl}/rpds/lookup`,
    mrRogersBaseUrl: '/ss/mrg',
    billingLink: 'https://www.numberguru.com/faq/billing',
    customPlanLink: 'https://forms.gle/YGhtDkrAwYoJirHYA',
    mapboxStyleUrl: 'mapbox://styles/beenverified/cloywa73e00sd01qnb8162waw',
    dataDeckRootURI: 'hk/dd/v2',
    chatLink: 'https://www.numberguru.com/chat/',
    topHapBaseUrl: 'https://widgets.tophap.com/api/widgets',
    cancelSurveyLink:
      'https://docs.google.com/forms/d/e/1FAIpQLSc23UsuE4dtQlR4z-mQaZXinDJb45UjtGXYkiFmWPrgu5PK1A/viewform',
    setupSsoLink: 'https://www.numberguru.com/sso_connections',
  },
  featureFlags: {
    hasCriminalEnabled: true,
    propertyReportFlags: [],
  },
  data: {
    brandName: 'NumberGuru',
    brandAbbreviation: 'ng' as 'ng',
    brandSupportEmail: 'support@numberguru.com',
    legalName: 'NumberGuru LLC',
    supportPhone: '1-866-673-9048',
    customerSupportPhone: '1-888-579-5910',
    supportEmail: 'support@numberguru.com',
    complianceEmail: 'compliance@numberguru.com',
    pdfPrice: '$4.95',
    rewardsDisclaimer:
      'Certain products and services, including vehicle purchase/sale opportunities, provided above include offers from third parties and/or external partners from whom we may receive limited compensation. Please be advised that these offers are provided as a convenience and are a sample, but do not necessarily include ALL offers, products or services available in the marketplace.',
    carGeniusPhone: '1-877-418-2717',
    upgradePlanCustomSolutionHeading: 'Need more reports or users?',
    upgradePlanCustomSolutionDescription: 'Let’s talk',
    limitReachedSubHeading:
      'We offer different solutions to match your search needs',
    contactOptionsCustomPlanDisplay: 'Request larger plan',
    customerSupportSchedule: { days: 'Mon-Fri', hours: '9am-5pm EST' },
    unclaimedMoneyDisclaimer:
      'Disclaimer: Unclaimed money search results may not be available for every state.',
    copyrightText:
      '© 2024 The NumberGuru LLC. All rights reserved. Number Guru® is a registered trademark of The NumberGuru LLC',
    brandCustomerSupportSchedule: { days: 'Mon - Fri', hours: '9am - 6pm EST' },
  },
  config: {
    socialMedia: [
      {
        textContent: 'Twitter',
        alternateTextContent: '@numberguru',
        href: 'https://www.x.com/numberguru',
        imgLogoUrl: facebookTwitter,
        icon: <CustomIcons.TwitterIcon sx={{ color: 'primary.dark' }} />,
        hideOnSide: false,
      },
      {
        textContent: 'Facebook',
        alternateTextContent: 'facebook.com/NumberGuru',
        href: 'https://www.facebook.com/NumberGuru',
        imgLogoUrl: facebookLogo,
        icon: (
          <Facebook fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
        ),
        hideOnSide: false,
      },
    ],
    searchTabs: [
      {
        displayName: 'People',
        id: 'peopleTab',
        icon: <Person fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />,
        class: 'noBorderBottom noBorderRight',
        internalTabs: [
          {
            label: 'Search By Name',
            mobileLabel: 'Name',
            libFormComponentName: 'SearchPersonForm',
            eventCategory: 'People Search',
          },
          {
            label: 'Search By Address',
            mobileLabel: 'Address',
            libFormComponentName: 'SearchPersonByAddressForm',
            eventCategory: 'Person search by address',
          },
          {
            label: 'Search By Job Title',
            mobileLabel: 'Job Title',
            libFormComponentName: 'SearchContactForm',
            customTrackEvent: {
              category: 'contact search',
              action: 'navigation click',
              label: 'search',
            },
          },
        ],
      },
      {
        displayName: 'Phone',
        id: 'phoneTab',
        icon: <Phone fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />,
        class: 'noBorderBottom noBorderRight',
        internalTabs: [
          {
            label: 'Search By Phone Number',
            mobileLabel: 'Phone Number',
            libFormComponentName: 'SearchPhoneForm',
            eventCategory: 'Phone Search',
          },
        ],
      },
      {
        displayName: 'Property',
        id: 'propertyTab',
        icon: <Home fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />,
        class: 'noBorderBottom',
        internalTabs: [
          {
            label: 'Address Report',
            mobileLabel: 'Report',
            libFormComponentName: 'SearchPropertyForm',
            eventCategory: 'property',
          },
          {
            label: 'Pre-foreclosure',
            mobileLabel: 'Pre-foreclosure',
            libFormComponentName: 'SearchPFCPropertiesForm',
            eventCategory: 'Pre-Foreclosure',
          },
        ],
      },
      {
        displayName: 'Email',
        id: 'emailTab',
        icon: <Email fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />,
        class: 'noBorderRight',
        internalTabs: [
          {
            label: 'Search By Email',
            mobileLabel: 'Email',
            libFormComponentName: 'SearchEmailForm',
            eventCategory: 'Email Search',
          },
        ],
      },
      {
        displayName: 'Username',
        id: 'usernameTab',
        icon: (
          <AlternateEmail
            fontSize="small"
            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
          />
        ),
        class: 'noBorderRight',
        internalTabs: [
          {
            label: 'Search By Username',
            mobileLabel: 'Username',
            libFormComponentName: 'SearchUsernameForm',
            eventCategory: 'Username Search',
          },
        ],
      },
    ],
    sideMenuItems: [
      {
        route: '',
        icon: (
          <Summarize sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '24px' }} />
        ),
        text: 'Reports',
        name: 'reports',
        state: '',
        'data-cy': 'reports',
        childItems: [
          {
            route: '/dashboard/',
            icon: (
              <Restore
                sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '24px' }}
              />
            ),
            text: 'Recent Reports',
            name: 'recentReports',
            state: '',
            'data-cy': 'recent_reports',
          },
          {
            route: '/dashboard/lists',
            icon: (
              <List sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '24px' }} />
            ),
            text: 'Saved Reports and Lists',
            state: '',
            name: 'lists',
            'data-cy': 'saved_reports_and_list',
          },
        ],
      },
      {
        route: '',
        icon: (
          <Search sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '24px' }} />
        ),
        text: 'More Searches',
        state: '',
        name: 'more-searches',
        childItems: [
          {
            route: '/dashboard/unclaimed-money',
            icon: (
              <AttachMoney
                sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '24px' }}
              />
            ),
            text: 'Unclaimed Money',
            state: '',
            name: 'unclaimedMoney',
            'data-cy': 'unclaimed_money',
            isLocked: true,
          },
        ],
      },

      {
        route: '',
        icon: (
          <NotificationsActive
            sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '24px' }}
          />
        ),
        text: 'Monitoring Hub',
        state: '',
        name: 'monitoring-hub',
        childItems: [
          {
            route: '/dashboard/monitoring',
            icon: (
              <NotificationsNone
                sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '24px' }}
              />
            ),
            text: 'Monitoring',
            state: '',
            name: 'monitoring',
          },
          {
            route: '/dashboard/dark-web',
            icon: (
              <CustomIcons.DarkWebIcon
                color="primary"
                sx={{ fontSize: '24px' }}
              />
            ),
            text: 'Dark Web',
            state: '',
            name: 'darkWebScan',
            'data-cy': 'dark_web_scan',
            isLocked: true,
          },
        ],
      },
      {
        route: '/dashboard/billing',
        icon: (
          <AccountCircle
            sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '24px' }}
          />
        ),
        text: 'My Account',
        state: '',
        name: 'myAccount',
        'data-cy': 'my_account',
      },
    ],
    reportTypeFilterOptions,
    higherConfidenceThreshold: 50,
    hardcodedPlans: [],
  },
  keys: {
    rapidsAPIKey: 'gwMrutsU42YWEEhXtzgK',
    mapboxKey:
      'pk.eyJ1IjoiYmVlbnZlcmlmaWVkIiwiYSI6ImNqeXQzbWNwbDAwMjEzbHBmdm1idWg3aXUifQ.NLnKyZ_Uuy1PMmKcV6XhgQ',
    mrRogersAPIKey: 'wDsk1fswDrpxSkRIVfiXkWzqjShrKmp6',
    streetViewStaticApiKey: 'AIzaSyC4tN7cbPEOaU30DwiK60QHOlx4LqXHO5w',
    topHapApiKey: 'si_MKritZu1BytMaH',
  },
  migrationPlanNames: [
    '22_86_1_month_nofree_afill_limit100reports_freepdf_numberguru_plan_migration',
  ],
};
