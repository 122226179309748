import { alpha, Box, Grid, styled } from '@mui/material';

export const SearchFormsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 1, 1),
  borderRadius: theme.shape.borderRadius,

  [theme.breakpoints.up('md')]: {
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    padding: theme.spacing(3, 2),
  },
}));

export const SearchWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0, 1),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 2, 0),
    marginTop: theme.spacing(3.5),
  },
}));

export const ReportContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),

  backgroundColor: theme.palette.background.paper,

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 2, 2),
    margin: 0,
  },
}));

export const DetailsContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(2, 2, 0),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  },
}));
