import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { initialize } from '@ltv-apm-modules/react';
import Analytics from 'analytics';
import { useQueryParam, StringParam } from 'use-query-params';
import googleTagManager, {
  type GoogleTagManagerConfig,
} from '@analytics/google-tag-manager';
import { version } from '@ltvco/refresh-lib';
import { ErrorBoundary, styled } from '@ltvco/refresh-lib/theme';
import packageInfo from '../package.json';
import { Recycling } from 'components/recycling/Recycling';
import QueryWrapper from 'components/queryWrapper/QueryWrapper';
import { Growthbook } from 'components/Growthbook';
import { LibContextWrapper } from 'components/LibContextWrapper';
import { goToLogin } from 'utils/goToLogin';
import { AppRoutes as Routes } from './AppRoutes';
import 'App.css';
import {
  CancelFinalStepModal,
  closeSnackbar,
  ConfirmCancelModal,
  ContactOptionsModal,
  invalidateSession,
  isUserLoggedIn,
  type SearchData,
  SessionProvider,
  SsoPromoBanner,
  TosModalLegalError,
  useSession,
} from '@ltvco/refresh-lib/v1';
import { HeaderParent } from 'components/HeaderParent/HeaderParent';
import {
  BannerMigrationCta,
  Footer,
  SearchFormProvider,
} from '@ltvco/refresh-lib/v2';
import { generateFooterLinks } from 'utils/footerLinks/footerLinks';
import { AppContainer } from 'components/AppContainer';
import type { ErrorRequestData, ResponseError } from '@ltvco/refresh-lib/utils';

let otel: any = null;
if (
  import.meta.env.VITE_TARGET_ENV === 'production' ||
  import.meta.env.VITE_TARGET_ENV === 'staging'
) {
  otel = initialize({
    name: 'refresh-numberguru',
    url: import.meta.env.VITE_APM_URL,
    apikey: import.meta.env.VITE_APM_KEY,
  });
}

declare global {
  interface Window {
    env: Record<string, string>;
  }
}

const HeaderContainer = styled('div')`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
`;

const SsoBannerContainer = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const AppContent = styled('div')(({ theme }) => ({
  maxWidth: '1600px',
  margin: 'auto',
}));

const AppFooter = styled('div')(({ theme }) => ({
  maxWidth: '1400px',
  margin: theme.spacing(0),
  padding: theme.spacing(0, 2, 4),
  position: 'relative',
  backgroundColor: theme.palette.background.paper,

  [theme.breakpoints.up('xs')]: {
    margin: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 2, 4, 9),
  },
}));

const LibVersion = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(2)} 0 0 0`,
  fontSize: theme.typography.fontSize * 0.75,
}));

const isUpgradeFormVariation = JSON.parse(
  localStorage.getItem('isUpgradeFormVariation') || 'false'
);

function App() {
  const analytics = Analytics({
    app: 'refresh-beenverified',
    debug: true,
    plugins: [
      googleTagManager({
        containerId: 'GTM-38C2',
        dataLayer: (window as any)?.dataLayer ?? [],
      } as GoogleTagManagerConfig),
    ],
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { session } = useSession();
  const [isCoreLoginRedirect] = useQueryParam(
    'is_core_login_redirect',
    StringParam
  );
  const [isAuthenticated, setIsAuthenticated] = useState(isUserLoggedIn());
  const [isContactOptionsModalOpen, setIsContactOptionsModalOpen] =
    useState(false);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const [isCancelFinalStepModalOpen, setIsCancelFinalStepModalOpen] =
    useState(false);

  const [tosState, setTosState] = useState<{
    modalOpen: boolean;
    requestData: ErrorRequestData['body'] | null;
  }>({
    modalOpen: false,
    requestData: null,
  });

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    if (
      isCoreLoginRedirect === 'true' &&
      !sessionStorage.getItem('has_tracked_core_login_redirect')
    ) {
      analytics.track('site_event', {
        event_name: 'login_attempt',
        type: 'Web',
        success: true,
      });

      sessionStorage.setItem(
        'has_tracked_core_login_redirect',
        isCoreLoginRedirect
      );
    }
  }, [isCoreLoginRedirect]);

  async function handleLogoutClick(ga4Ttracker?: () => void) {
    await invalidateSession();
    setIsAuthenticated(isUserLoggedIn());
    closeSnackbar();
    goToLogin('https://www.numberguru.com');
    if (ga4Ttracker) {
      ga4Ttracker();
    }
  }

  function isResponseError(error: any): error is ResponseError {
    return error.response !== undefined;
  }

  function logError(
    context: string,
    error: Error | ResponseError,
    url: string | undefined
  ) {
    const isProdEnv = import.meta.env.VITE_TARGET_ENV === 'production';
    if (!isProdEnv || !otel) return;

    const { account } = session;
    const { api } = otel;
    if (api.isOTELInitialized()) {
      if (account) {
        api.setUser({
          id: account?.account.user_info.user_code,
          email: account?.account.user_info.email,
        });
      }
      let errorMsg = `${context}: ${error.message}, Cause: ${error.cause}`;
      if (isResponseError(error)) {
        errorMsg = `${errorMsg}, Status: ${
          error.response.status
        }, Data: ${JSON.stringify(error.response)}`;
      }
      if (url) {
        errorMsg += `, URL: ${url}`;
      }
      api.pushError(new Error(errorMsg));
    }
  }

  function trackEventGA4(eventToTrack: object, eventName?: string) {
    const targetEnv = import.meta.env.VITE_TARGET_ENV;
    if (targetEnv === 'dev') return;
    analytics?.track(eventName || 'site_event', eventToTrack);
  }

  function trackEvent(category: string, action: string, label?: string) {
    analytics.track('ua_event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }

  const onLoginSuccess = () => {
    let next = '/';

    if (location.state && location.state.next) {
      next = location.state.next;
    }

    navigate(
      next === '/' ||
        (next.startsWith('/report') && !next.includes('permalink'))
        ? '/dashboard'
        : next
    );
    setIsAuthenticated(isUserLoggedIn());
  };

  const contactUsHandler = () => {
    setIsContactOptionsModalOpen(true);
  };

  const handleSearchSuccess = ({
    reportType,
    searchParams,
    permalink,
  }: SearchData) => {
    const url = `/report/${reportType}?${searchParams}&permalink=${permalink}`;
    navigate(url);
  };

  const openConfirmCancelModal = (downsellTest?: boolean) => {
    setIsConfirmCancelModalOpen(true);
  };

  const onCancelConfirmCancelModal = () => {
    setIsConfirmCancelModalOpen(false);
    setIsCancelFinalStepModalOpen(true);
  };

  return (
    <ErrorBoundary
      redirectFunction={() => {
        navigate('/error');
      }}
      location={location}
      logFunction={logError as any}
      trackEventFunction={trackEventGA4}
    >
      <QueryWrapper
        handleLogout={handleLogoutClick}
        trackEventGA4={trackEventGA4}
        setTosState={setTosState}
      >
        <SessionProvider isAuthenticated={isAuthenticated}>
          <Growthbook>
            <LibContextWrapper
              trackEventGA4={trackEventGA4}
              logError={logError}
              trackEvent={trackEvent}
              handleLogoutClick={handleLogoutClick}
            >
              <SearchFormProvider>
                <div className="app">
                  <HeaderContainer>
                    <SsoBannerContainer>
                      <SsoPromoBanner />
                      <BannerMigrationCta />
                    </SsoBannerContainer>
                    <HeaderParent handleSearchSuccess={handleSearchSuccess} />
                  </HeaderContainer>
                  <AppContainer>
                    <Routes
                      handleLogoutClick={handleLogoutClick}
                      handleSearchSuccess={handleSearchSuccess}
                      contactUsHandler={contactUsHandler}
                      onLoginSuccess={onLoginSuccess}
                      openConfirmCancelModal={openConfirmCancelModal}
                      onCancelConfirmCancelModal={onCancelConfirmCancelModal}
                    />
                    <Recycling isAuthenticated={isAuthenticated} />
                  </AppContainer>
                  <ContactOptionsModal
                    isOpen={isContactOptionsModalOpen}
                    setIsOpen={setIsContactOptionsModalOpen}
                    openConfirmCancelModal={() => {
                      setIsConfirmCancelModalOpen(true);
                    }}
                  />
                  <ConfirmCancelModal
                    isOpen={isConfirmCancelModalOpen}
                    onClose={() => setIsConfirmCancelModalOpen(false)}
                    onCancel={() => {
                      setIsConfirmCancelModalOpen(false);
                      setIsCancelFinalStepModalOpen(true);
                    }}
                  />
                  <CancelFinalStepModal
                    isOpen={isCancelFinalStepModalOpen}
                    onClose={() => setIsCancelFinalStepModalOpen(false)}
                  />
                  <TosModalLegalError
                    tosState={tosState}
                    setTosState={setTosState}
                    handleSearchSuccess={handleSearchSuccess}
                  />

                  <Footer
                    footerLinks={generateFooterLinks({
                      contactUsHandler,
                    })}
                    libVersion={
                      <LibVersion>
                        {import.meta.env.VITE_TARGET_ENV === 'production' ? (
                          <code>{version}</code>
                        ) : (
                          <code>
                            {packageInfo.dependencies['@ltvco/refresh-lib']}
                          </code>
                        )}
                      </LibVersion>
                    }
                    hasMobileApps
                    openConfirmCancelModal={openConfirmCancelModal}
                  />
                </div>
              </SearchFormProvider>
            </LibContextWrapper>
          </Growthbook>
        </SessionProvider>
      </QueryWrapper>
    </ErrorBoundary>
  );
}

export default App;
